import React, {useState, useRef} from 'react'
import './App.css';
import {Home} from './components/Home'
import {Site} from './components/site/Site'
import {Drive} from './components/drive/Drive'
import {Worker} from './components/worker/Worker'
import {Detail} from './components/detail/Detail'
import {Step} from './components/step/Step'
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer'
import Global from './components/Global'

const global = new Global()

function App() {
  const app = 'Synchronize'

  const [search, Search]  = useState("")
  const route = useRef("/")
  
  return (

    <BrowserRouter>

    <Header app={app} global={global} onSearch={Search} />

    <div className='container'>

      <Routes>    
        <Route path='/' element={ <Home app={app} global={global} search={search} />} exact />
        <Route path='/site' element={ <Site global={global} search={search} />} />
        <Route path='/drive' element={ <Drive global={global} search={search} />} />
        <Route path='/worker' element={ <Worker global={global} search={search} />} />
        <Route path='/detail' element={ <Detail global={global} search={search} />} />
        <Route path='/step' element={ <Step global={global} />} />
      </Routes>
      
</div>
    <Footer/>

    </BrowserRouter>

  )
}

export default App;


/*
 {  global.connected() ? 
  <Navigate to='/drive' />
: 
  <Navigate to='/' />
}

 */