import React from 'react';
import {meSplitter} from './shared/meLayout'

import { Timers, Focused } from './Timer/Timers'
/*
import Global from './Global'
const global = new Global()
*/

function Checkbox(box = {name:"Checked", value:false}) {
    const [checked, setChecked] = React.useState(box.value);
  
    return (
        <div className="form-group form-row">
      <label className="control-label col-sm-2" style={{width:"auto", textAlign:"right"}}>
          {box.name}:
        <input type="checkbox" name={box.name}
          defaultChecked={checked}
          onChange={() => setChecked(!checked)}
        />
        
      </label>
      </div>
    )
}

export class Home extends React.Component{

  constructor(props){
    super(props)
    this.state = {modalShow:false}

    this.splitter = new meSplitter("Side", "HomeContainer" )
    this.api = {
      defaults : {
        baseURL : this.props.global.apiUrl,
        domain : this.props.app
      } 
    }
    //alert(`api ${this.api.defaults.baseURL} - ${this.api.defaults.domain}`)
  }

  componentDidMount(){
    this.splitter.selectorAll()
    //alert(`splitter ${this.splitter.items.length}`)
  }


  modalClose =() => {
      this.setState({modalShow:false})
  }

  onMouseUp = (event) => {
    this.splitter.mouseUp(event, this.api)
  }

  render() {
        return (
          this.props.global.connected() ? 
      
      <div className="mt-8 d-flex justify-content-left Sides" id="HomeContainer"
            onMouseDown={(e)=>{this.splitter.mouseDown(e)}} 
            onMouseMove={(e)=>{this.splitter.mouseMove(e)}} 
            onMouseUp={this.onMouseUp}            
            >

          <div className="side" id="SideTree" style={{width: '25%', bottom:0, verticalAlign:'bottom'}}>
                <div>{"Workers"}</div>
            </div>

            <div className="side" id="SideDetail" style={{width: '55%'}}>
              <h2>Synchronize...</h2>
              <Checkbox name='Connected' value={true} />
            </div>

            <div className="side" id="SideTimer" style={{width: '20%'}}>
              <Timers element={0}/>
            </div>
    </div>

: 
<div>
<h2 style={{color:'red'}}>Synchronize...</h2>
<Checkbox name='Connected' value={false}  />
</div>



        )
    }
}

