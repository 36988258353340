import React, {useState, useEffect} from 'react';
import {FormControl, Row, Col, Navbar} from 'react-bootstrap';
import {NavLink} from 'react-router-dom'; 
import {Navigation} from './Navigation';
import SignModal from './sign/SignModal'

const Header = ({app, global, onSearch}) => {

    const [showing, setShowing] = useState(false);

    const onChange = (e) => {
        //alert(e.target.value)
        onSearch(e.target.value)

        //this.forceUpdate()
        //console.log('Search',e.target.value, search)
    }

    const logo = global.logo

    const Logout = (e) => {
        //alert('remove localStorage')
        localStorage.removeItem('RDS')
        global.store=null
        window.location.reload()
    }

    const Login = (e) => {
        setShowing(true)
    }

    useEffect(() => {
        onSearch('')
        setShowing(false)
    }, [])    

    //console.log('Header.global',global)
    return (
        
        <div className="Header">
            <Row className="navbar navbar-custom" expand="lg" style={{justifyContent:"center", display: "flex"}}>
                <Col sm={1}>
                    <NavLink to="/" >
                        <img className="Logo" src={logo} alt="Home" height="90px" padding="5px"  />
                    </NavLink>
                </Col>
                <Col  sm={6}>
                    <Navigation  global={global} />
                </Col>
                <Col  sm={1} style={{ textAlign: 'right' }}>
                    <i className="fa fa-search" style={{ fontSize:'1.5rem' }} onClick={onChange} ></i>
                </Col>
                <Col  sm={3}>
                    <FormControl 
                        className="d-inline p-2" 
                        style={{ textAlign: 'center', backgroundColor:'lightgray' }} 
                        name="Search" type="text" 
                        defaultValue=""
                        onChange={onChange}
                        placeholder="Search Items" />
                </Col>
                <Col  sm={1}>
                <Navbar >
                { global.connected() ?
                    <NavLink className="d-inline p-3 navbar-custom" to="/" style={{float:'right'}} onClick={Logout} >Exit</NavLink>
                :
                    <NavLink className="d-inline p-3 navbar-custom" to="/" style={{float:'right'}} onClick={Login} >Login</NavLink>  
                }
                </Navbar>
                </Col>
            </Row>

{ (showing || !global.connected()) ?
<SignModal 
    app={app}
    show={showing}
    logo={logo}
    onHide={()=>{setShowing(false)}}

/>
: null}            
        </div>
        
    )
}

export default Header;