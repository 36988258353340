import React from 'react';

const Footer = () => {
    return (
        <div className="Footer">
            <p>@ Rais Digital Services 240328</p>
        </div>
    )
}

export default Footer;